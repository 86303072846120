/*Home*/
.front-left {
	float: left;
	width: 50%;
	height: 100%;
	background: url('../images/temp/fleft.png') no-repeat right top 120px;

	@media (max-width: $tablet) {
		background: url('../images/temp/fleft.png') no-repeat right top 110px;
		background-size: auto 200px;
		height: 440px;
	}

	.wrap {
		float: right;
		width: 613px;
		position: relative;
		height: 100%;

		@media (max-width: $xlarge) {
			width: 100%;
		}
	}

	.nav {
		position: absolute;
		left: -195px;
		top: 360px;
		transform: rotate(-90deg);

		@media (max-width: $xlarge) {
			left: -181px;
		}

		@media (max-width: $tablet) {
			display: none;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				float: left;
				list-style: none;
				font-size: 19px;
				margin: 0 30px;
				font-family: 'DIN Pro Black';
			}
		}
	}

	.front-text {
		position: absolute;
		bottom: 132px;
		left: 66px;
		width: 310px;

		@media (max-width: $tablet) {
			display: none;
		}

		p {
			margin-bottom: 58px;
			color: $marine;
		}

		img {
			margin-bottom: 29px;
		}
	}

	footer {
		position: absolute;
		right: 13px;
		bottom: 42px;

		@media (max-width: $tablet) {
			display: none;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				float: left;
				list-style: none;
				font-size: 16px;
				font-weight: bold;
				color: $black;
				margin-left: 53px;
				font-family: 'DIN Pro Black';

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}

.front-right {
	float: right;
	width: 50%;
	height: 100%;
	background: #ffec12 url('../images/temp/fright.png') no-repeat left top 138px;

	@media (max-width: $xlarge) {
		background-size: auto 100%;
	}

	@media (max-width: $tablet) {
		background: #ffec12 url('../images/temp/fright.png') no-repeat left top 116px;
		background-size: auto 74%;
		height: 440px;
	}

	.wrap {
		float: left;
		width: 613px;
		height: 100%;
		position: relative;

		@media (max-width: $xlarge) {
			width: 100%;
		}
	}

	.social {
		position: absolute;
		right: 0;
		bottom: 43px;
		width: 20px;
		overflow: hidden;

		@media (max-width: $xlarge) {
			right: 15px;
		}

		@media (max-width: $tablet) {
			display: none;
		}

		.lang {
			float: left;
			margin-bottom: 32px;
			font-size: 12px;
			transform: rotate(-90deg);
			margin-left: -3px;
			margin-top: 3px;
		}

		img {
			margin-bottom: 17px;
		}
	}

	.slogan {
		position: absolute;
		bottom: 121px;
		left: 65px;

		@media (max-width: $xlarge) {
			width: 74%;
		}

		@media (max-width: $tablet) {
			left: 0;
			margin-left: -95px;
			width: 226px;
			max-width: 226px;
			bottom: 26px;
		}
	}

	footer {
		position: absolute;
		left: 40px;
		bottom: 42px;

		@media (max-width: $tablet) {
			display: none;
		}

		p {
			font-size: 16px;
			margin: 0;
		}
	}
}

/*About*/
.page {
	position: relative;

	.logo {
		margin-left: -15px;

		@media (max-width: $xlarge) {
			margin-left: 0;
		}

		@media (max-width: $tablet) {
			width: 91px;
			margin-left: 14px;
		}
	}



	header {
		height: 251px;
		display: inline-block;
		width: 100%;
		margin-bottom: 25px;

		@media (max-width: $tablet) {
			height: auto;
			margin-bottom: 46px;
		}
	}

	h1 {
		font-size: 56px;
		color: $marine;
		font-family: 'DIN Pro Black';
		margin-bottom: 32px;

		@media (max-width: $xlarge) {
			font-size: 52px;
		}

		@media (max-width: $large) {
			font-size: 46px;
		}

		@media (max-width: $tablet) {
			font-size: 25px;
		}
	}

	.back {
		float: right;
		margin-top: 223px;
		margin-right: 87px;
		position: relative;
		z-index: 1000;

		@media (max-width: $tablet) {
			margin: 79px 3px 0 0;
			z-index: 10;

			img {
				width: 20px;
			}
		}
	}

	.nav {
		position: absolute;
		left: -195px;
		top: 360px;
		transform: rotate(-90deg);
		z-index: 1;

		@media (max-width: $xlarge) {
			left: -181px;
		}

		@media (max-width: $tablet) {
			display: none;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				float: left;
				list-style: none;
				font-size: 19px;
				font-weight: bold;
				margin: 0 30px;
				font-family: 'DIN Pro Black';

				&.normal {
					font-family: 'DIN Pro';

					img {
						width: 14px;
					}
				}
			}
		}
	}

	&.product-page {
		.nav {
			left: -410px;
    		top: 587px;

    		@media (max-width: $xlarge) {
    			left: -393px;
    		}
		}
	}
}

@keyframes fruc1 {
	0% {transform: translate(0, -20px);}
	50% {transform: translate(0, 0);}
	100% {transform: translate(0, -20px);}
}

@keyframes fruc2 {
	0% {transform: translate(0, -10px);}
	50% {transform: translate(0, 0);}
	100% {transform: translate(0, -10px);}
}

@keyframes fruc3 {
	0% {transform: translate(0, -25px);}
	50% {transform: translate(0, 0);}
	100% {transform: translate(0, -25px);}
}

.about-fruc {
	padding-left: 65px;
	max-width: 485px;
	width: 100%;

	@media (max-width: $xlarge) {
		padding-left: 55px;
	}

	@media (max-width: $large) {
		padding-left: 45px;
	}

	@media (max-width: $tablet) {
		max-width: 100%;
		padding: 0 14px;
	}

	p {
		font-size: 20px;
		color: $marine;

		@media (max-width: $xlarge) {
			font-size: 18px;
		}

		@media (max-width: $large) {
			font-size: 16px;
		}

		@media (max-width: $tablet) {
			font-size: 14px;
		}
	}
}

.about-animation {
	@media (max-width: $tablet) {
	    text-align: center;
	    margin-top: 40px;
	}

	.fruc-1 {
		position: relative;
		margin-left: -40px;
		animation-timing-function: ease-in-out;
		animation: fruc1 6s infinite;
		z-index: 10;

		@media (max-width: $xlarge) {
			margin-left: 0;
			width: 36%;
		}

		@media (max-width: $tablet) {
			width: 28%;
		}
	}

	.fruc-2 {
		position: relative;
		margin-left: -40px;
		animation: fruc2 3s infinite;
		z-index: 1;

		@media (max-width: $xlarge) {
			width: 23%;
		}

		@media (max-width: $tablet) {
			width: 20%;
			margin-left: -10px;
		}
	}

	.fruc-3 {
		position: relative;
		margin-left: -20px;
		animation: fruc3 5s infinite;
		z-index: 10;

		@media (max-width: $xlarge) {
			width: 30%;
		}

		@media (max-width: $tablet) {
			width: 24%;
			margin-left: -10px;
		}
	}
}

.pogoji {
	padding: 0 0 0 65px;

	@media (max-width: $tablet) {
		padding: 0 14px;
	}

	h4 {
		font-size: 18px;
		margin-bottom: 20px;
		font-family: 'DIN Pro Black';
	}

	table {
		margin-bottom: 30px;
		width: 100%;

		td {
			padding: 0 10px 5px 0;
			border-bottom: 1px #c6c6c6 solid;
			font-size: 13px;
		}
	}

	p {
		@media (max-width: $tablet) {
			font-size: 11px;
			margin-bottom: 12px;
		}
	}
}

.modal-dialog {
	max-width: 860px;

	.modal-content {
		border-radius: 0;
		border: 0;
		padding: 0;
	}

	.modal-body {
		padding: 53px 47px 43px 109px;

		h1 {
			font-size: 34px;
			margin-bottom: 58px;
			max-width: 80%;
		}

		h4 {
			font-size: 18px;
			margin-bottom: 20px;
			font-family: 'DIN Pro Black';
		}

		table {
			margin-bottom: 30px;
			width: 100%;

			td {
				padding: 0 10px 5px 0;
				border-bottom: 1px #c6c6c6 solid;
				font-size: 13px;
			}
		}

		p {
			font-size: 13px;
		}

		.scrollpage {
			height: 511px;
		}
	}

	.close {
		position: absolute;
		right: 35px;
		top: 38px;
		opacity: 1;
		z-index: 1000;
	}
}

/*Slider*/
.slider-page {
	header {
		height: 120px;
		margin-bottom: 72px;

		@media (max-width: $tablet) {
			z-index: 100;
			height: auto;
			position: fixed;
			left: 0;
			top: 0;
			padding: 0 15px 20px;
			background: rgba(255,255,255,0.7);
			margin-bottom: 0;
		}
	}

	.footer-single {
		margin-top: 50px;

		@media (max-width: $tablet) {
			position: fixed;
			bottom: 0;
			width: 100%;
			height: 94px;
			background: $white;
			left: 0;
			z-index: 10;
			margin-top: 0;
			padding-top: 20px;
		}
	}
}

.white-close {
	position: absolute;
	right: 103px;
	top: 30px;
	z-index: 100;

	@media (max-width: $xlarge) {
		right: 53px;
	}

	@media (max-width: $tablet) {
		display: none;
	}
}

.swiper-slide {
	text-align: center;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-wrap {
	position: relative;

	.row {
		width: 100%;
	}
}

.fruc-slider-page {
	@media (max-width: $tablet) {
		position: fixed;

		.page {
			height: calc(100% - 124px);
			position: relative;
			width: 100%;
			top: 124px;

			.main {
				overflow-y: scroll !important;
				-webkit-overflow-scrolling: touch;
				height: calc(100% - 94px);
				position: fixed;
				left: 0;
				margin: 0 15px 124px;
  			}
		}
	}

	.main {
		@media (max-width: $xlarge) {
			padding-left: 55px;
			padding-right: 55px;
		}

		@media (max-width: $tablet) {
			padding-left: 15px;
			padding-right: 15px;

			.swiper-wrap {
				height: 100%;
				padding-bottom: 94px;
			}
		}
	}

	@media (max-width: $tablet) {
		overflow: hidden;

		.burger {
			bottom: 23px;
		}
	}
}

.bottles-thumbs.swiper-container {
	@media (max-width: $tablet) {
		height: 100% !important;
		
		.swiper-wrapper {
			height: 100% !important;
			transform: translate3d(0px, 0px, 0px) !important;
			transition-duration: 0ms !important;
			display: flex;
			flex-direction: column;
			position: relative;
			width: auto;

			.swiper-slide {
				height: auto !important;
				margin-bottom: 30px;
			}
		}
	}
}

.swiper-button-next {
	width: 13px;
	height: 14px;
	background: url('../images/icons/adown.svg') no-repeat center center;
	transform: rotate(-90deg);
	margin: -7px 0 0 0;
	right: 214px;
}

.swiper-button-prev {
	width: 13px;
	height: 14px;
	background: url('../images/icons/aup.svg') no-repeat center center;
	transform: rotate(-90deg);
	margin: -7px 0 0 0;
	left: 214px;
}

@media (min-width: $tablet-min) {
	@keyframes frucb1 {
		0% {transform: translate(0, 0);}
		100% {transform: translate(0, -25px);}
	}

	@keyframes frucb2 {
		0% {transform: translate(0, 0);}
		100% {transform: translate(0, -35px);}
	}
}

.bottles-thumbs {
	&.swiper-container {
		height: 800px;
		max-width: 1080px;

		@media (max-width: $tablet) {
			height: 100%;
		}

		.swiper-slide {
			@media (max-width: $tablet) {
				background-size: cover !important;
				padding: 28px;
				display: block;
			}

			@media (max-width: $tablet) {
				padding: 28px 15px 50px;
			}

			.row {
				height: 100%;
				text-align: left;

				@media (max-width: $tablet) {
					display: inline-block;
					width: 100%;

					.col-md-6 {
						text-align: center;
						padding: 0;
					}

					.col-md-5 {
						width: 100%;
					}
				}
			}

			h2 {
				font-size: 56px;
				line-height: 56px;
				margin-bottom: 5px;
				color: $marine;
				margin-top: 77px;

				@media (max-width: $xlarge) {
					font-size: 46px;
					line-height: 46px;
					margin-top: 40px;
				}

				@media (max-width: $large) {
					font-size: 36px;
					line-height: 36px;
				}

				@media (max-width: $tablet) {
					font-size: 25px;
					line-height: 25px;
					margin-top: 5px;
				}
			}

			h4 {
				font-size: 22px;
				color: $marine;
				margin-bottom: 35px;

				@media (max-width: $xlarge) {
					font-size: 21px;
				}

				@media (max-width: $large) {
					font-size: 20px;
				}

				@media (max-width: $tablet) {
					font-size: 18px;
					margin-bottom: 10px;
				}
			}

			p {
				font-size: 16px;
				line-height: 1.63;
				color: $marine;

				@media (max-width: $large) {
					font-size: 15px;
				}

				@media (max-width: $tablet) {
					font-size: 14px;
				}
			}

			&.f1,
			&.f2,
			&.f3,
			&.f4,
			&.f5,
			&.f6,
			&.f7,
			&.f8,
			&.f10,
			&.f11 {
				background-size: 100%;

				.bt-1 {
					position: absolute;
					left: -50px;
					top: 60px;
					width: 482px;
					transform: translate(0, -25px);

					@media (max-width: $tablet) {
						height: 302px;
						width: auto;
						left: auto;
						display: inline-block;
						position: relative;
						transform: translate(0, 0);
						top: 0;
					}

					@media (max-width: $small) {
						height: 252px;
					}
				}

				.bt-2 {
					position: absolute;
					left: 211px;
					top: 216px;
					width: 382px;
					transform: translate(0, -35px);

					@media (max-width: $xlarge) {
						width: 76%;
						height: auto;
						left: 30%;
					}

					@media (max-width: $tablet) {
						height: 243px;
						width: auto;
						left: auto;
						margin-left: -81px;
						position: relative;
						display: inline-block;
						transform: translate(0, 0);
						top: 29px;
					}

					@media (max-width: $small) {
						height: 184px;
						margin-left: -71px;
					}
				}

				&.swiper-slide-active {
					.bt-1 {
						animation-timing-function: ease-in-out;
						animation: frucb1 2s;
					}

					.bt-2 {
						animation-timing-function: ease-in-out;
						animation: frucb2 2s;
					}
				}
			}

			&.f9 {
				background-size: 100%;

				.bt-2 {
					position: absolute;
					left: 70px;
					top: 216px;
					width: 382px;
					transform: translate(0, -35px);

					@media (max-width: $tablet) {
						height: 243px;
						width: auto;
						left: auto;
						margin-left: -81px;
						position: relative;
						display: inline-block;
						transform: translate(0, 0);
						top: 29px;
					}

					@media (max-width: $small) {
						height: 194px;
						margin-left: 0;
						top: 0;
					}
				}

				&.swiper-slide-active {
					.bt-2 {
						animation-timing-function: ease-in-out;
						animation: frucb2 2s;
					}
				}
			}


			&.f1 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_poli.jpg') no-repeat center top;
			}

			&.f2 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_vita.jpg') no-repeat center top;
			}

			&.f3 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_visnja.jpg') no-repeat center top;
			}

			&.f4 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_izi.jpg') no-repeat center top;
			}

			&.f5 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_multivitamin.jpg') no-repeat center top;
			}

			&.f6 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_kopoli.jpg') no-repeat center top;
			}

			&.f7 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_borovnica.jpg') no-repeat center top;
			}

			&.f8 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_vita.jpg') no-repeat center top;
				
				h2 {
					@media (min-width: $tablet-min) {
						font-size: 48px;
						line-height: 54px;
						margin-top: 62px;
					}
				}
			}

			&.f9 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_iso.jpg') no-repeat center top;
			}

			&.f10 {
				background: url('../images/backgrounds/fruc_web_ozadja_2000px_limonada.jpg') no-repeat center top;
			}

			&.f11 {
				background: url('../images/backgrounds/fruc_web_ozadja_grenivka.jpg') no-repeat center top;

				.bt-1 {
					left: 20px;

					@media (max-width: $tablet) {
						left: auto;
					}
				}
			}
		}
	}
}

.hranilne {
	.hv {
		color: $marine;
		font-size: 20px;
		font-family: 'DIN Pro Black';
		border-bottom: 2px $marine solid;
		padding-bottom: 2px;
		position: relative;

		@media (max-width: $xlarge) {
			font-size: 18px;
		}

		@media (max-width: $large) {
			font-size: 16px;
		}

		@media (max-width: $tablet) {
			font-size: 12px;
		}

		&::after {
			position: absolute;
			right: -22px;
			top: 9px;
			width: 14px;
			height: 11px;
			background: url('../images/icons/triangle.svg') no-repeat;
			content: "";

			@media (max-width: $tablet) {
				top: 5px;
				background-size: 8px;
			}
		}

		&.opened {
			&::after {
				transform: rotate(180deg);
			}
		}
	}

	.h-info {
		width: 415px;
		margin-top: 15px;
		display: none;
		max-height: 195px;

		small {
			font-size: 12px;
			color: $marine;
			margin-bottom: 10px;
			display: inline-block;
			width: 100%;
		}

		@media (max-width: $large) {
			width: 100%;
		}

		table {
			width: 100%;
			color: $marine;
			margin-bottom: 20px;

			td {
				padding: 0 10px 5px 0;
				font-size: 15px;

				&:first-child {
					width: 60%;
				}

				@media (max-width: $xlarge) {
					font-size: 14px;
				}

				@media (max-width: $large) {
					font-size: 13px;
				}

				@media (max-width: $tablet) {
					font-size: 12px;
				}
			}
		}
	}
}

.link-thumbs {
	position: absolute;
	left: -345px;
	top: 307px;
	transform: rotate(-90deg);
	width: 672px !important;

	@media (max-width: $xlarge) {
		left: -375px;
	}

	@media (max-width: $tablet) {
		visibility: hidden;
		height: 1px !important;
		width: 1px !important;
	}

	&.swiper-container {
		height: 60px;

		.swiper-slide {
			font-size: 12px;
			cursor: pointer;
			padding: 0 15px;

			&.swiper-slide-thumb-active {
				font-size: 20px;
				line-height: 20px;
				font-family: 'DIN Pro Black';	
				padding: 0 10px;
			}
		}
	}
}

/*List*/
.product-page {
	@media (min-width: $tablet-min) {
		.products {
			min-height: 648px; 
		}
	}

	header {
		height: 190px;

		@media (max-width: $tablet) {
			height: auto;
		}
	}

	.back {
		display: none;

		@media (max-width: $tablet) {
			display: block;
		}
	}
}

.products {
	padding-left: 55px;

	@media (max-width: $tablet) {
		padding: 0 15px;
	}
}

.prm {
	@media (min-width: $tablet-min) {
		width: 27.2%;
	}

	@media (max-width: $tablet) {
		margin-bottom: 20px;
		max-width: 60%;
	}

	@media (max-width: $small) {
		max-width: 70%;
	}

	img {
		margin-bottom: 5px;
	}

	a {
		font-size: 20px;
		line-height: 24px;
		font-family: 'DIN Pro Black';
		color: $marine;

		@media (max-width: $tablet) {
			font-size: 16px;
			line-height: 20px;
		}

		&:nth-child(2) {
			width: 84%;
			display: inline-block;
		}
	}
}

.products {
	@media (max-width: $tablet) {
		.prm {
			&:nth-child(even) {
				float: right;
			}
		}
	}
}

@media (min-width: $tablet-min) {
	.prm-2 {
		margin: -24.5% 0 0 29%;
	}

	.prm-2v {
		width: 37%;
		margin: -24.5% 0 0 29%;
	}

	.prm-3 {
		margin: -24.5% 0 0 58%;
	}

	.prm-4 {
		margin: -16% 0 0 0;
	}

	.prm-5 {
		margin: -15% 0 0 29%;
	}

	.prm-6 {
		margin: -15.4% 0 0 58%;
	}

	.prm-7 {
		margin: -19.4% 0 0 0;
	}

	.prm-8 {
		margin: -35% 0 0 29%;
	}

	.prm-9 {
		margin: -20% 0 0 67.6%;
	}

	.prm-10 {
		margin: -3% 0 0 110px;
	}
}

.nav-1,
.nav-2 {
	@media (min-width: $tablet-min) {
		display: block !important;
	}
}

.pagination {
	display: none;

	@media (max-width: $tablet) {
		display: inline-block;
		width: 100%;
		text-align: center;
		margin-top: 60px;

		ul {
			padding: 0;

			li {
				list-style: none;
				display: inline-block;

				a {
					display: inline-block;
					width: 42px;
					height: 42px;
					border: 1px #9b9b9b solid;
					color: #9b9b9b;
					border-radius: 50%;
					margin: 0 8px;
					text-align: center;
					font-size: 15px;
					padding-top: 8px;

					&.active {
						color: $marine;
						border-color: $marine;
					}
				}
			}
		}		
	}
}

.nagradna {
	background: #ffbd50;

	h4 {
		font-size: 24px;
		color: $marine;
		margin-bottom: 25px;
		text-transform: uppercase;
		font-family: 'DIN Pro Black';
		text-align: left;
		padding: 0 89px;

		@media (max-width: $tablet) {
			padding: 0 30px;
		}

	}

	.back {
		margin-top: 90px;
		margin-right: -20px;

		@media (max-width: $tablet) {
			margin-right: 0;
			margin-top: 80px;
		}
	}
}

.nagradna-box {
	padding-left: 204px;

	@media (max-width: $tablet) {
		padding: 0 15px;

		.btn {
			width: 100%;
			font-size: 14px;
			margin-bottom: 62px;
		}
	}

	p {
		color: $marine;
		font-size: 18px;
		margin-bottom: 50px;

		@media (max-width: $tablet) {
			font-size: 14px;
			margin-bottom: 22px;
		}
	}

	label {
		margin-bottom: 15px;
		font-size: 18px;

		@media (max-width: $tablet) {
			font-size: 14px;
		}
	}

	small {
		display: inline-block;
		width: 100%;
		margin-bottom: 30px;
		font-size: 12px;

		@media (max-width: $tablet) {
			margin-bottom: 19px;
		}
	}

	input[type="text"],
	input[type="email"] {
		width: 100%;
		border: 0;
		border-radius: 8px;
		height: 40px;
		margin-bottom: 37px;
		padding: 0 15px;
		font-size: 18px;

		&::placeholder { 
			color: red;
			opacity: 1;
			color: #9b9b9b;
		}

		&:-ms-input-placeholder {
			color: #9b9b9b;
		}

		&::-ms-input-placeholder {
			color: #9b9b9b;
		}

		@media (max-width: $tablet) {
			margin-bottom: 19px;
		}

		&.small-bottom {
			margin-bottom: 5px;
		}
	}

	.check {
		font-size: 12px;
		margin-bottom: 20px;

		a {
			text-decoration: underline;
		}

		@media (max-width: $tablet) {
			font-size: 10px;
		}
	}
}

.ngr {
	display: block;
}

.tnx {
	display: none;
	padding-left: 204px;

	h1 {
		margin-bottom: 120px;
	}

	a {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		line-height: 22px;
		font-family: 'DIN Pro Black';
		border: 2px $white solid;
		border-radius: 10px;
		text-align: center;
		padding: 0 20px 4px;
		height: 72px;
		color: $marine;

		&:hover {
			background: $white;
		}
	}

	.right {
		float: right;
		max-width: 80%;
	}
}