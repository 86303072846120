.footer {
	display: none;
	width: 100%;
	margin-top: 12px;
	padding-bottom: 29px;

	@media (max-width: $tablet) {
		display: block;
	}

	.front-text {
		padding: 0 29px;

		img {
			margin-bottom: 13px;
			width: 102px;
		}

		p {
			margin-bottom: 20px;
		}

		.btn {
			width: 100%;
		}
	}

	.copy {
		margin-top: 53px;
		text-align: center;

		ul {
			display: inline-block;
			padding: 0;
			margin: 0;

			li {
				float: left;
				list-style: none;
				font-size: 11px;
				font-weight: bold;
				color: $black;
				margin-left: 27px;
				font-family: 'DIN Pro Black';

				&:first-child {
					margin-left: 0;
				}
			}
		}

		p {
			display: inline-block;
			width: 100%;
			font-size: 11px;
			margin: 0;
		}
	}
}

.footer-single {
	text-align: center;
	margin-top: 108px;
	padding-bottom: 40px;

	@media (max-width: $tablet) {
		padding-bottom: 29px;
		margin-top: 40px;		
	}

	.social-wrap {
		float: right;
	}

	.social {
		position: fixed;
		bottom: 43px;
		width: 20px;
		overflow: hidden;

		@media (max-width: $xlarge) {
			right: 15px;
		}

		@media (max-width: $tablet) {
			display: none;
		}

		.lang {
			float: left;
			margin-bottom: 32px;
			font-size: 12px;
			transform: rotate(-90deg);
			margin-left: -3px;
			margin-top: 3px;
		}

		img {
			margin-bottom: 17px;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			font-size: 16px;
			margin: 0 26px;

			@media (max-width: $tablet) {
				font-size: 11px;
				padding: 0 13px;
				margin: 0 0 4px;

				&:nth-child(5) {
					display: inline-block;
					width: 100%;
					margin: 0;
				}
			}

			p {
				margin: 0;
			}

			a {
				font-family: 'DIN Pro Black';
			}
		}
	}
}