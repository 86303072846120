.logo {
	margin-top: 80px;

	@media (max-width: $xlarge) {
		margin-left: 15px;
	}

	@media (max-width: $tablet) {
		width: 91px;
		margin-left: 29px;
	}
}

.game-wrap {
	position: fixed;
	z-index: 100;

	@media (max-width: $tablet) {
		top: 0;
		right: 0;
		width: 100%;
		height: 57px;
		z-index: 110;
	}

	@media (min-width: $tablet-min) {
		right: -131px;
		top: -45px;
		transform: rotate(45deg);
		width: 400px;
		height: 200px;
		overflow: hidden;
	}
}

.game {
	@media (min-width: $tablet-min) {
		position: absolute;
		right: 71px;
		top: 62px;
		transform: rotate(-45deg);
	}

	@media (max-width: $tablet) {
		top: 0;
		right: 0;
	}

	position: absolute;
	width: 241px;
	height: 239px;
	background: url('../images/icons/shape.svg') no-repeat;
	z-index: 100;

	@media (max-width: $tablet) {
		width: 100%;
		height: 57px;
		background: url('../images/icons/m-shap.png') repeat-x center top;
		background-size: auto 50px;
	}

	@media (max-width: $large) and (max-height: 500px) {
		width: 100%;
		height: 57px;
		background: url('../images/icons/m-shap.png') repeat-x center top;
		background-size: auto 50px;
	}
	
	h2 {
		text-align: center;
		font-size: 20px;
  		font-weight: 900;
		position: absolute;
		left: 111px;
		top: 52px;
		transform: rotate(45deg);
		width: 94px;

		@media (max-width: $large) and (max-height: 500px) {
			left: 0;
			top: 0;
			transform: rotate(0);
			width: 100%;
			font-size: 15px;
			position: relative;
			margin-top: 13px;
			display: inline-block;
		}

		@media (max-width: $tablet) {
			left: 0;
			top: 0;
			transform: rotate(0);
			width: 100%;
			font-size: 15px;
			position: relative;
			margin-top: 13px;
			display: inline-block;
		}
	}

	a {
		color: $white;
		font-weight: bold;
	}
}
