.btn {
	display: inline-block;
	background: $marine;
	color: $white;
	border-radius: 11px;
	text-align: center;
	padding: 5px 10px 7px 10px;
	width: 200px;
	font-size: 20px;
	font-weight: 900;

	&:hover,
	&:focus {
		background: $black;
		color: $white;
	}
}