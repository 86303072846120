.container-fluid {
	max-width: $container-fluid;
}

html,
body {
	width: 100%;
	height: 100%;
	float: left;
}

a {
	color: $black;

	&:hover,
	&:focus {
		color: $marine;
		outline: none!important;
		text-decoration: none;
	}
}

body {
	color: $black;
	font-size: $body-font-size;
	font-family: 'DIN Pro';
}

.cc-banner .cc-message,
.cc-btn {
	font-family: 'DIN Pro';
	font-size: 14px;
}

img {
	max-width: 100%;
}

p {
	margin: 0 0 30px;
}

*:focus {
    outline: none;
}

.locked {
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100%;
}

h1,
h2,
strong,
b {
	font-family: 'DIN Pro Black';
}

.scrollpage {
	@media (max-width: $tablet) {
		height: 435px;
		padding: 0 14px;
	}

	@media (max-width: $small) {
		height: 335px;
	}
}

.mCSB_scrollTools .mCSB_draggerRail {
	background: #d8d8d8;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background: $black !important;
}

.mobile-link {
	display: none !important;

	@media (max-width: $tablet) {
		display: inline-block !important;
	}
}

.desktop-link {
	display: inline-block !important;

	@media (max-width: $tablet) {
		display: none !important;
	}
}