.keyvisual {
	margin: 0 50px 40px;
	background: url('../images/fruc-web-ozadja-vita.jpg') no-repeat;
	background-size: cover;
	height: 560px;
	padding: 40px 57px 27px;

	@media (max-width: $tablet) {
		height: auto;
		padding: 20px;
		margin: 0 15px 40px;

		.btn {
			font-size: 16px;
			width: 100%;
		}

		img {
			max-width: 90%;
		}
	}
}

.vita-slogan {
	margin-top: 80px;

	@media (max-width: $tablet) {
		margin-top: 30px;
	}

	img {
		margin-bottom: 30px;

		@media (max-width: $tablet) {
			max-width: 80%;
		}
	}

	p {
		color: $marine;
		font-size: 16px;
		margin-bottom: 30px;

		@media (max-width: $tablet) {
			font-size: 14px;
		}
	}
}