/*LAYOUT*/
$container-fluid:        1226px;

/*FONT*/
$body-font-size:   16px;

/*COLORS*/
$black:       #000000;
$white:       #FFFFFF;
$marine:      #07255b;
$orange:      #ffbd50;


/*HEADINGS*/
$h1-size:          30px;
$h2-size:          30px;
$h3-size:          24px;
$h4-size:          18px;
$h5-size:          16px;
$h6-size:          14px;

/*Breakpoints*/
$xxlarge: 1630px;
$xlarge: 1250px;
$large: 1024px;
$tablet: 767px;
$tablet-min: 768px;
$small: 540px;
