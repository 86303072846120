@font-face {
	font-family: 'DIN Pro';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/DINPro-Medium.woff') format('woff');
}



@font-face {
	font-family: 'DIN Pro Black';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/DINPro-Black.woff') format('woff');
}
