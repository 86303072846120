.mobile-menu {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: $orange;
	color: $white;

	.mm-header {
		padding-top: 78px;
		position: relative;
		z-index: 100;
	}

	.mlogo {
		margin-left: 29px;
	}

	.close-link {
		float: right;
		margin-right: 16px;
		color: $white;
		font-size: 14px;
		padding-right: 36px;
		background: url('../images/icons/button-nazaj.svg') no-repeat right center;
		padding-bottom: 2px;
		margin-top: 3px;
	}

	nav {
		position: absolute;
		top: 50%;
		margin-top: -140px;
		left: 0;
		width: 100%;
		text-align: center;

		@media (max-height: 500px) {
			margin-top: -84px;
		}

		@media (max-height: 450px) {
			margin-top: -48px;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				width: 100%;
				margin-bottom: 22px;

				@media (max-height: 500px) {
					margin-bottom: 10px;
				}

				@media (max-height: 450px) {
					margin-bottom: 0;
				}

				a {
					width: 100%;
					color: $white;
					font-size: 24px;
					font-family: 'DIN Pro Black';

					@media (max-height: 500px) {
						font-size: 18px;
					}

					@media (max-height: 450px) {
						font-size: 16px;
					}
				}
			}
		}
	}

	.social {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;
		position: fixed;
		bottom: 0;
		width: 100%;
		left: 0;

		a {
			margin: 0 15px;

			&.lang {
				margin-top: 3px;
				font-size: 12px;
			}
		}
	}
}

.burger {
	display: none;
	z-index: 10;

	@media (max-width: $tablet) {
		display: block;
		position: fixed;
		right: 24px;
		bottom: 76px;
	}

	@media (max-height: 500px) {
		bottom: 26px;
	}
}
